* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* overflow hidden on X so that the ProductSlider doesnt make the whole page scollable on X axis.
   Applying this property on both html and body will break the position: stycky in the side product panel in the [product].tsx */
/* body {
  overflow-x: hidden;
} */
html {
  scroll-behavior: smooth;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  overflow-x: hidden;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
a:link,
a:visited,
a:active {
  outline: none;
  color: #000;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a:hover {
  opacity: 0.7;
  text-decoration: none;
  cursor: pointer;
}

div:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

.cursorPointer {
  outline: none;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.cursorPointer:hover {
  opacity: 0.7;
  text-decoration: none;
  cursor: pointer;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s,
    transparent 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, transparent 5000s;
} */
