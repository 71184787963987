#nprogress .bar {
  height: 5px;
  z-index: 10000;
  background: #c1a578;
}

#nprogress .peg {
  box-shadow: 0 0 10px #c1a578, 0 0 5px #c1a578;
}

#nprogress .spinner-icon {
  width: 15px;
  height: 15px;
  /* border: solid 3px transparent; */
  /* background: #c1a578; */

  border-top-color: #c1a578;
  border-left-color: #c1a578;
}

#nprogress .spinner {
  z-index: 10000;
  top: 12px;
  left: 1px;
}
